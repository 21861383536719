import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import styles from "./ImageUseesPlaceholderPopup.module.css";

const ImageUsesPlaceholderPopup = (props) => {
  const [imageUseOptions, setImageUseOptions] = useState({
    type: "image_uses",
    show_original_image: true,
    original_image_label: "",
    show_original_image_name: true,
    original_image_name_label: "",
    show_byline: false,
    byline_label: "",
    show_license_fee: true,
    license_fee_label: "",
    show_found_image: false,
    found_image_label: "",
    show_page_link: true,
    page_link_label: "",
    additional_images_text: "",
    created: null,
    updated: "",
  });
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (props.oldImageUseOptions) setImageUseOptions(props.oldImageUseOptions);
  }, [props]);

  const handleInputChange = (event) => {
    const { type, name } = event.target;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;
    setImageUseOptions((prevImageUseOptions) => ({
      ...prevImageUseOptions,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setIsClicked(true);
    if (
      !imageUseOptions.original_image_label.trim() ||
      !imageUseOptions.original_image_name_label.trim() ||
      !imageUseOptions.additional_images_text ||
      (imageUseOptions.show_byline && !imageUseOptions.byline_label.trim()) ||
      !imageUseOptions.license_fee_label.trim() ||
      (imageUseOptions.show_found_image &&
        !imageUseOptions.found_image_label.trim())
    ) {
      return;
    } else {
      const nowDate = new Date();
      const timedSettting = (prevImageUseOptions) => ({
        ...prevImageUseOptions,
        updated: nowDate,
        ...(prevImageUseOptions.created ? {} : { created: nowDate }),
      });

      setImageUseOptions((prevImageUseOptions) => {
        console.log(prevImageUseOptions);
        const updatedSetting = timedSettting(prevImageUseOptions);
        handleAfterStateUpdate(updatedSetting);
        return updatedSetting;
      });

      setIsClicked(false);
      handleClose();
    }
  };
  const handleClose = () => {
    setImageUseOptions({
      type: "image_uses",
      show_original_image: false,
      original_image_label: "",
      show_original_image_name: false,
      original_image_name_label: "",
      show_byline: false,
      byline_label: "",
      show_license_fee: false,
      license_fee_label: "",
      show_found_image: false,
      found_image_label: "",
      show_page_link: false,
      page_link_label: "",
      created: "",
      updated: "",
    });
    setIsClicked(false);
    props.close();
  };
  const handleAfterStateUpdate = (updatedSetting) => {
    props.onAdd("image_uses", updatedSetting);
  };
  return (
    <DialogPopup
      title="CONFIGURE IMAGE USES PLACEHOLDER"
      open={props.open}
      onClose={handleClose}
    >
      <div className={styles.popup_body}>
        <h4 className={styles.subheader}>Always shown</h4>
        <div>
          <TextfieldInput
            id="outlined-basic"
            name="original_image_label"
            label="Label for original image"
            value={imageUseOptions.original_image_label}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            placeholder='e.g. "Our Image"'
            inputProps={{ maxLength: 32 }}
            required
            error={isClicked && !imageUseOptions.original_image_label}
          />
        </div>

        <div>
          <TextfieldInput
            id="outlined-basic"
            name="original_image_name_label"
            label="Label for original image name"
            value={imageUseOptions.original_image_name_label}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            placeholder='e.g. "Image id"'
            inputProps={{ maxLength: 32 }}
            required
            error={isClicked && !imageUseOptions.original_image_name_label}
          />
        </div>
        <div className={styles.image_text}>
          <TextfieldInput
            id="outlined-basic"
            name="license_fee_label"
            label="Label for license fee"
            value={imageUseOptions.license_fee_label}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            placeholder='e.g. "License fee"'
            inputProps={{ maxLength: 32 }}
            required
            error={isClicked && !imageUseOptions.license_fee_label}
          />
        </div>
        <div className={styles.image_text}>
          <TextfieldInput
            id="outlined-basic"
            name="page_link_label"
            label="Label for page link"
            value={imageUseOptions.page_link_label}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            placeholder='e.g. "Page link"'
            inputProps={{ maxLength: 32 }}
          />
        </div>
        <div>
          <TextfieldInput
            id="outlined-basic"
            name="additional_images_text"
            label="Additional images disclaimer"
            value={imageUseOptions.additional_images_text}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            placeholder='e.g. "{{count}} more image(s) not displayed here"'
            required
            error={isClicked && !imageUseOptions.additional_images_text}
          />
          <p className={styles.explainatory_text}>
            The above text is shown when there are more than 3 image uses in the
            case. To show the number of remaining image uses please write{" "}
            {'"{{count}}"'} as a placeholder.
          </p>
        </div>
        <h4 className={styles.subheader}>Optional</h4>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_found_image"
                  checked={imageUseOptions.show_found_image}
                  onChange={handleInputChange}
                />
              }
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="found_image_label"
              label="Label for page screenshot"
              value={imageUseOptions.found_image_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              placeholder='e.g. " Screenshot of found image"'
              inputProps={{ maxLength: 32 }}
              required={imageUseOptions.show_found_image}
              error={
                isClicked &&
                imageUseOptions.show_found_image &&
                !imageUseOptions.found_image_label
              }
            />
          </div>
        </div>
        <div className={styles.image_option}>
          <div className={styles.image_check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="show_byline"
                  checked={imageUseOptions.show_byline}
                  onChange={handleInputChange}
                />
              }
            />
          </div>
          <div className={styles.image_text}>
            <TextfieldInput
              id="outlined-basic"
              name="byline_label"
              label="Label for by-line"
              value={imageUseOptions.byline_label}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              placeholder='e.g. "Photographer"'
              inputProps={{ maxLength: 32 }}
              required={imageUseOptions.show_byline}
              error={
                isClicked &&
                imageUseOptions.show_byline &&
                !imageUseOptions.byline_label
              }
            />
          </div>
        </div>
      </div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="btn_confirm"
          onClick={handleSubmit}
          style={{ width: "175px" }}
        >
          {props.wordClicked ? "Update placeholder" : "Add placeholder"}
        </Button>
      </div>
    </DialogPopup>
  );
};

export default ImageUsesPlaceholderPopup;
